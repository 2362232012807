import * as THREE from "three";
import { useEffect, useRef, RefObject } from "react";
import useHotspotStore from "../../../../components/Hotspots/store";

type GroupElementProps = JSX.IntrinsicElements["group"];

interface ColliderProps extends GroupElementProps {
  node: THREE.Group | THREE.Object3D;
  groupPath?: string;
}

const Collider = ({
  node: { children, type: _, ...node },
  groupPath,
  ...props
}: ColliderProps) => {
  const ref = useRef<THREE.Object3D>();
  const addOcclusionCollider = useHotspotStore(
    (state) => state.addOcclusionCollider,
  );

  const buildPath = (part: string) => `${groupPath}.${part}`;

  useEffect(() => {
    addOcclusionCollider(ref as RefObject<THREE.Object3D>);
  }, []);

  return (
    <group ref={ref} {...node} {...props}>
      {children.map((child) => (
        <mesh key={buildPath(child.name)} {...child} />
      ))}
    </group>
  );
};

export default Collider;
