import { TransformControls } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useControls } from "leva";
import { useRef } from "react";
import Pinpoint from "../Hotspots/Pinpoint";

const PinpointControl = () => {
  const ref = useRef();
  const [{ position }, set] = useControls(() => ({
    position: {
      value: [0, 0, 0],
    },
  }));

  useFrame(() => {
    if (ref.current) {
      // @ts-ignore: untyped ref
      set({ position: ref.current.object.position.toArray() });
    }
  });

  return (
    // @ts-ignore: untyped ref
    <TransformControls ref={ref} position={position}>
      <Pinpoint pinpoint={{ position: [0, 1, 0] }} />
    </TransformControls>
  );
};

export default PinpointControl;
