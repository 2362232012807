import { useEffect, useState } from "react";
import useHotspotStore from "../../store";

const useAnimationAction = (action: AnimationAction) => {
  const { setToggle } = useHotspotStore();
  const [active, setActive] = useState(false);

  const trigger = () => {
    setActive(!active);
  };

  useEffect(() => {
    setToggle(action.group_name, active ? action.animation : false);
  }, [active, action.group_name, action.animation]);

  return [active, trigger] as const;
};

export default useAnimationAction;
