interface LabelProps {
  text: string;
}

const Label = ({ text }: LabelProps) => {
  return (
    <div className="label-pin pointer-events-none ml-1 flex w-36 flex-wrap drop-shadow-2xl">
      {text.split(" ").map((label, i) => (
        <div
          className=" -mr-1 -mt-px w-max select-none bg-white pl-1 pr-1 "
          key={`hotspot-label-${i}`}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default Label;
