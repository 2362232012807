import OverlayGrid from "../../../../components/Overlay/OverlayGrid";
import Player from "../../../../components/Overlay/Player";
import Side from "../../../../components/Overlay/Side";
import TimeMachineLink from "../../../../components/Overlay/TimeMachineLink";
import ToggleLabels from "../../../../components/Overlay/ToggleLabels";
import AnimationControls from "./AnimationControls";

import "./Overlay.module.css";

interface OverlayProps {
  model?: Model;
}

const Overlay = ({ model }: OverlayProps) => {
  return (
    <OverlayGrid
      columns="2fr 1fr"
      rows="auto auto 10vw"
      areas={["side link", "side .", "side audio"]}
    >
      <OverlayGrid.Area area="link">
        {model?.toggleLabels && <ToggleLabels />}
      </OverlayGrid.Area>

      {model && (
        <>
          <OverlayGrid.Area
            area="side"
            className="model period-title overflow-y-hidden"
          >
            <Side resource={model}>
              {model.animated_groups && (
                <AnimationControls animated_groups={model.animated_groups} />
              )}
            </Side>
          </OverlayGrid.Area>
        </>
      )}

      <OverlayGrid.Area area="audio" className="flex items-end justify-end">
        <Player />
      </OverlayGrid.Area>
    </OverlayGrid>
  );
};

export default Overlay;
