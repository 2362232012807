import AudioPlayer from "../AudioPlayer";
import useHotspotStore from "../Hotspots/store";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

const Player = () => {
  const setAudio = useHotspotStore((state) => state.setAudio);
  const audio = useHotspotStore((state) => state.audio);

  if (!audio) {
    return null;
  }

  return (
    <div className="pointer-events-auto flex w-[370px] flex-col items-end pr-8 pb-8 lg:pb-6 lg:pr-6  md:w-[260px] md:pb-4 md:pr-4 sm:w-[260px]">
      <div className="flex w-full items-center justify-between bg-black/80 px-2 pt-2 text-white">
        <div className="overflow-hidden text-ellipsis whitespace-nowrap pr-2 text-sm">
          {audio.title}
        </div>
        <CloseIcon
          className="w-4 cursor-pointer"
          onClick={() => {
            setAudio();
          }}
        />
      </div>
      <AudioPlayer audio={audio.src}></AudioPlayer>
    </div>
  );
};

export default Player;
