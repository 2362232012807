import useResourceSrcQuery from "../../../hooks/useResourceSrcQuery";

interface ImageElemProps {
  className: string;
  src: string;
}
const ImageElem = ({ className, src }: ImageElemProps) => {
  const { data: imageUrl } = useResourceSrcQuery(src!);
  return <img className={className} src={imageUrl} />;
};

export default ImageElem;
