import { useState } from "react";
import useHotspotStore from "../../store";

const useModalAction = (action: ModalAction) => {
  const [active, setActive] = useState(false);
  const setModalContent = useHotspotStore((store) => store.setModalContent);

  const trigger = () => {
    setModalContent(action.content);
  };

  return [active, trigger] as const;
};

export default useModalAction;
