import { useQuery } from "react-query";
import DLResource from "../utils/DLResource";

const useResourceSrcQuery = (uuid: string) => {
  return useQuery("src", async () => {
    if (uuid.startsWith("local-")) {
      return (await (
        await fetch(`/json/model/${uuid.slice(6)}.json`)
      ).json());
    }
    const dlResource = await DLResource.get(uuid);
    return dlResource.url.href;
  });
};

export default useResourceSrcQuery;
