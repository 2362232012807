import { RefObject } from "react";
import { Object3D } from "three";
import create from "zustand";

interface HotspotStoreState {
  camera: {
    look_at: Vector3Tuple;
    move_to: Vector3Tuple;
  };
  setCamera: (look_at: Vector3Tuple, move_to: Vector3Tuple) => void;
  resetCamera: () => void;

  toggle_list: { [key: string]: boolean | Vector3Tuple };
  setToggle: (toggle: string, isActive: false | Vector3Tuple) => void;

  active_animation: boolean;
  setActiveAnimation: (value: boolean) => void;

  list_action?: ListAction;
  setActionList: (action: ListAction) => void;

  modal_content?: ModalContent;
  setModalContent: (content?: ModalContent) => void;

  occlusion_collider_list: RefObject<Object3D>[];
  addOcclusionCollider: (collider: RefObject<Object3D>) => void;

  audio?: AudioSource;
  setAudio: (audio?: AudioSource) => void;

  show_labels: boolean;
  setShowLabels: (value: boolean) => void;

  show_labels_distance: boolean;
  setShowLabelsOnDistance: (value: boolean) => void;
}

const useHotspotStore = create<HotspotStoreState>((set) => ({
  camera: {
    look_at: [0, 0, 0],
    move_to: undefined!,
  },
  setCamera: (look_at: Vector3Tuple, move_to: Vector3Tuple) =>
    set(() => ({ camera: { look_at, move_to } })),
  resetCamera: () =>
    set(() => ({ camera: { look_at: undefined!, move_to: undefined! } })),

  toggle_list: {},
  setToggle: (toggle: string, isActive: false | Vector3Tuple) =>
    set(({ toggle_list }) => ({
      toggle_list: { ...toggle_list, [toggle]: isActive },
    })),
  active_animation: false,
  setActiveAnimation: (value: boolean) =>
    set(() => ({ active_animation: value })),

  list_action: undefined,
  setActionList: (action?: ListAction) =>
    set(() => ({
      list_action: action,
    })),

  audio: undefined,
  setAudio: (audio) => set(() => ({ audio })),

  modal_content: undefined,
  setModalContent: (content?: ModalContent) =>
    set(() => ({ modal_content: content })),

  occlusion_collider_list: [],
  addOcclusionCollider: (collider: RefObject<Object3D>) =>
    set(({ occlusion_collider_list }) => ({
      occlusion_collider_list: [...occlusion_collider_list, collider],
    })),

  show_labels: false,
  setShowLabels: (value: boolean) => set(() => ({ show_labels: value })),

  show_labels_distance: true,
  setShowLabelsOnDistance: (show_labels_distance) =>
    set(() => ({ show_labels_distance })),
}));

export default useHotspotStore;
