import * as THREE from "three";
import CameraControlsLib from "camera-controls";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import useHotspotStore from "./Hotspots/store";
import { PerspectiveCamera, Vector3 } from "three";

class CameraControlsImpl extends THREE.EventDispatcher<Event> {
  enabled = true;
}

CameraControlsLib.install({ THREE });
extend({ CameraControlsLib });

interface CameraControlsProps {
  position?: THREE.Vector3Tuple;
  min_distance?: number;
  max_distance?: number;
}

const maxLabelDistance = 25;

const CameraControls = ({
  position = [0, 2, 3],
  min_distance = 2,
  max_distance,
}: CameraControlsProps) => {
  const ref = useRef<CameraControlsLib>();
  const { gl, get, set } = useThree();
  const { camera }: { camera: PerspectiveCamera } = useThree();
  const controls = useMemo(() => new CameraControlsImpl(), []);
  const [enabled, setEnabled] = useState(true);

  const { look_at, move_to } = useHotspotStore((state) => state.camera);
  const resetCamera = useHotspotStore((state) => state.resetCamera);
  const setShowLabelsOnDistance = useHotspotStore(
    (state) => state.setShowLabelsOnDistance,
  );
  const active_animation = useHotspotStore((state) => state.active_animation);

  camera.position.set(...position);

  const calculateDistance = () => {
    if (ref.current) {
      setShowLabelsOnDistance(ref.current.distance <= maxLabelDistance);
    }
  };

  // TODO move into configuration file
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("rest", calculateDistance);
      ref.current.addEventListener("wake", calculateDistance);
      calculateDistance();
      ref.current.dollySpeed = 0.4;
      ref.current.azimuthRotateSpeed = 0.45;
      ref.current.polarRotateSpeed = 0.45;
      ref.current.truckSpeed = 0.7;

      ref.current.restThreshold = 0.1025;

      ref.current.minDistance = min_distance;
      if (max_distance) {
        ref.current.maxDistance = max_distance;
      }
      ref.current.minPolarAngle = 0;
      ref.current.maxPolarAngle = Math.PI  - Math.PI / 30;

      ref.current.dampingFactor = 0.1; // Velocità di easing animazione pan e rotazione

      camera.near = 0.02;
      camera.updateProjectionMatrix();
    }
  }, []);

  useEffect(() => {
    const old = get().controls;
    set({ controls });
    return () => set({ controls: old });
  }, [controls, get, set]);

  useEffect(() => {
    if (look_at && move_to && ref.current) {
      ref.current.setLookAt(...move_to, ...look_at, true);
      resetCamera();
    }
  }, [look_at, move_to, resetCamera]);

  useFrame((_, delta) => {
    if (ref.current) {
      ref.current.update(delta);
      if (active_animation) {
        const pos = camera.position.add(new Vector3(0.0001, 0, 0)).toArray();
        ref.current.setPosition(...pos, false);
      }
    }
    if (controls && controls.enabled !== enabled) {
      setEnabled(controls.enabled);
    }
  });

  return (
    // @ts-expect-error
    <cameraControlsLib
      ref={ref}
      enabled={enabled}
      args={[camera, gl.domElement]}
    />
  );
};

export default CameraControls;
