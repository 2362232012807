import create from "zustand";

interface LoaderStoreState {
  loading: number;
  startLoading: () => void;
  completeLoading: () => void;
}

const useLoaderStore = create<LoaderStoreState>((set) => ({
  loading: 0,
  startLoading: () => set((state) => ({ loading: state.loading + 1 })),
  completeLoading: () => set((state) => ({ loading: state.loading - 1 })),
}));

export default useLoaderStore;
