import { ReactNode } from "react";
import Area from "./Area";

interface OverlayGridProps {
  areas?: string[];
  columns?: string;
  rows?: string;
  children?: ReactNode;
}

const OverlayGrid = ({
  children,
  columns = "auto",
  rows = "auto",
  areas = [],
}: OverlayGridProps) => {
  return (
    <div
      className={[
        "main_grid",
        "fixed",
        "grid",
        "top-0",
        "left-0",
        "bottom-0",
        "w-screen",
        "h-screen",
        // z index above of 1000 to always be above Pinpoints
        "z-[1001]",
        "pointer-events-none",
        "select-none",
      ].join(" ")}
      style={{
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        gridTemplateAreas: areas.reduce(
          (prev, curr) =>
            `${prev}
						"${curr}"`,
          "",
        ),
      }}
    >
      {children}
    </div>
  );
};

OverlayGrid.Area = Area;

export default OverlayGrid;
