import { ReactNode, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { ReactComponent as NextIcon } from "../../../icons/next.svg";
import { ReactComponent as PrevIcon } from "../../../icons/prev.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ImageElem from "./ImageElem";

interface ImageGalleryModalProps {
  content: GalleryModal;
}
const ImageGalleryModal = ({
  content: { gallery },
}: ImageGalleryModalProps) => {
  const [isEnd, SetIsEnd] = useState(false);
  const [isBeginning, SetIsBeginning] = useState(true);

  const handleChange = (isSlideBeginning: boolean, isSlideEnd: boolean) => {
    if (isBeginning !== isSlideBeginning) {
      SetIsBeginning(isSlideBeginning);
    }

    if (isEnd !== isSlideEnd) {
      SetIsEnd(isSlideEnd);
    }
  };

  return (
    <Swiper
      modules={[Pagination]}
      pagination
      style={{ boxSizing: "content-box" }}
      className="relative !-mx-8 w-full !px-8"
      spaceBetween={50}
      onSlideChange={({ isBeginning, isEnd }) =>
        handleChange(isBeginning, isEnd)
      }
    >
      {gallery &&
        gallery.map(({ src, text }, i = 0) => (
          <SwiperSlide
            key={i++}
            className="flex flex-col justify-center overflow-y-auto pb-6"
          >
            <ImageElem
              className="min-h-0 basis-full object-contain py-3"
              src={src}
            />
            {text && (
              <div className="modal-text basis-32 overflow-y-auto text-white">
                {text}
              </div>
            )}
          </SwiperSlide>
        ))}
      <Next disabled={isEnd} />
      <Prev disabled={isBeginning} />
    </Swiper>
  );
};

const NavButton = ({
  onClick,
  className,
  children,
  disabled = false,
}: {
  onClick: () => void;
  className: string;
  children: ReactNode;
  disabled?: boolean;
}) => {
  return (
    <div
      onClick={() => onClick()}
      className={`${className} ${
        disabled && "opacity-50"
      } absolute top-1/2 z-10 -translate-y-1/2 cursor-pointer select-none p-6 text-white`}
    >
      {children}
    </div>
  );
};

const Next = ({ disabled = false }: { disabled?: boolean }) => {
  const swiper = useSwiper();
  return (
    <NavButton
      disabled={disabled}
      onClick={() => swiper.slideNext()}
      className="-right-4"
    >
      <NextIcon className="w-4" />
    </NavButton>
  );
};

const Prev = ({ disabled = false }: { disabled?: boolean }) => {
  const swiper = useSwiper();
  return (
    <NavButton
      disabled={disabled}
      onClick={() => swiper.slidePrev()}
      className="-left-4"
    >
      <PrevIcon className="w-4" />
    </NavButton>
  );
};

export default ImageGalleryModal;
