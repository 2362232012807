import Toggle from "../../../../components/Toggle";
import useModelStore from "../../store";

interface AnimationToggleProps {
  group: AnimatedGroup;
}

const AnimationToggle = ({ group }: AnimationToggleProps) => {
  const setAnimation = useModelStore((state) => state.setAnimation);
  const handleChange = (value: boolean) => {
    setAnimation(group.group_name, value);
  };

  return (
    <Toggle onChange={handleChange}>
      <div className="pointer-events-auto w-full text-white md:text-sm">
        {group.label}
      </div>
    </Toggle>
  );
};

export default AnimationToggle;
