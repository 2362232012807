import useAnimationAction from "./useAnimationAction";
import useAudioAction from "./useAudioAction";
import useCameraAction from "./useCameraAction";
import useLinkAction from "./useLinkAction";
import useListAction from "./useListAction";
import useModalAction from "./useModalAction";

// HACK: There is probably a way to to this safer and better
const useAction = (action: Action) => {
  let useHook: (action: any) => readonly [boolean, () => void];
  if (action.type === "animation") {
    useHook = useAnimationAction;
  }
  if (action.type === "audio") {
    useHook = useAudioAction;
  }
  if (action.type === "camera") {
    useHook = useCameraAction;
  }
  if (action.type === "link") {
    useHook = useLinkAction;
  }
  if (action.type === "list") {
    useHook = useListAction;
  }
  if (action.type === "modal") {
    useHook = useModalAction;
  }
  return useHook!(action);
};

export default useAction;
