import { useEffect } from "react";
import useModelStore from "../../store";
import AnimationToggle from "./AnimationToggle";

interface AnimationControlsProps {
  animated_groups: AnimatedGroup[];
}

const AnimationControls = ({ animated_groups }: AnimationControlsProps) => {
  const setAnimatedGroups = useModelStore((state) => state.setAnimatedGroups);

  useEffect(() => {
    setAnimatedGroups(animated_groups);
  }, [animated_groups]);

  return (
    <div className="animItem mt-2 flex flex-col gap-2">
      <div className="pin-title mt-4 mb-2 flex items-center justify-between border-b border-white py-2 pr-10 font-[Roboto] text-xl font-[400] uppercase leading-[20px] text-white md:hidden ">
        Animazioni
      </div>
      {animated_groups.map((group) => (
        <AnimationToggle key={`${group.group_name}-toggle`} group={group} />
      ))}
    </div>
  );
};

export default AnimationControls;
