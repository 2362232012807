import useHotspotStore from "../store";
import ModalComponent from "../../Modal";
import { useEffect, useState } from "react";
import ImageModal from "./ImageModal";
import GigapixelModal from "./GigapixelModal";
import TextModal from "./TextModal";
import ImageGalleryModal from "./ImageGalleryModal";

const Modal = () => {
  const modal_content = useHotspotStore((state) => state.modal_content);
  const setOpenModal = useHotspotStore((state) => state.setModalContent);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (modal_content) {
      setOpen(true);
    }
  }, [modal_content]);

  const handleClose = () => {
    setOpen(false);
    setOpenModal(undefined);
  };

  if (!modal_content || !open) return null;

  return (
    <ModalComponent
      expandHeight={modal_content.type !== "text"}
      title={modal_content?.title}
      onClose={() => handleClose()}
    >
      {modal_content.type === "image" && <ImageModal content={modal_content} />}
      {modal_content.type === "text" && <TextModal content={modal_content} />}
      {modal_content.type === "gigapixel" && (
        <GigapixelModal content={modal_content} />
      )}
      {modal_content.type === "gallery" && (
        <ImageGalleryModal content={modal_content} />
      )}
    </ModalComponent>
  );
};

export default Modal;
