import * as THREE from "three";
import useModelStore from "../../store";
import AnimatedGroup from "./AnimatedGroup";
import Collider from "./Collider";

type GroupElementProps = JSX.IntrinsicElements["group"];

interface GLTFModelGroupProps extends GroupElementProps {
  node: THREE.Group | THREE.Object3D;
  groupPath?: string;
}

const Group = ({
  node: { children, type: _, ...node },
  groupPath = "",
  ...props
}: GLTFModelGroupProps) => {
  const buildPath = (part: string) => `${groupPath}.${part}`;
  const animated_groups = useModelStore((state) => state.animated_groups);

  const rendered_children = children.map((child) => {
    const childPath = buildPath(child.name);
    if (child.type === "Mesh") {
      return <mesh key={childPath} {...child} />;
    }
    if (child.name.includes("_colliders")) {
      return <Collider key={childPath} node={child} groupPath={groupPath} />;
    }
    return <Group key={childPath} node={child} groupPath={childPath} />;
  });

  if (animated_groups.some((group) => group.group_name === node.name)) {
    return (
      <AnimatedGroup {...node} {...props}>
        {rendered_children}
      </AnimatedGroup>
    );
  }
  return (
    <group {...props} {...node}>
      {rendered_children}
    </group>
  );
};

export default Group;
