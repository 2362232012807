import { Switch } from "@headlessui/react";
import { ReactNode, useState } from "react";

interface ToggleProps {
  children: ReactNode;
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

const Toggle = ({
  children,
  checked = false,
  onChange = () => null,
}: ToggleProps) => {
  const [enabled, setEnabled] = useState(checked);

  const handleChange = (value: boolean) => {
    setEnabled(value);
    onChange(value);
  };

  return (
    <div className="pointer-events-auto m-0 flex w-full justify-between">
      <Switch.Group>
        {children && (
          <Switch.Label className="mr-4 flex items-center">
            {children}
          </Switch.Label>
        )}
        <Switch
          checked={enabled}
          onChange={handleChange}
          className={`${enabled ? "bg-blue-400" : "bg-gray-400"}
    relative inline-flex h-[24px] w-[44px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            className={`${enabled ? "translate-x-5" : "translate-x-0"}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
};

export default Toggle;
