import { ReactComponent as CloseIcon } from "../../../icons/close.svg";
import useHotspotStore from "../../Hotspots/store";
import ResourceLink from "../ResourceLink";
import Item from "./Item";

interface ActionListProps {
  action: ListAction;
}

const ActionList = ({ action: { title, links, list } }: ActionListProps) => {
  const setActionList = useHotspotStore((state) => state.setActionList);

  return (
    <div className="action-main flex w-full flex-col overflow-hidden text-white">
      <div className="pin-title my-4 flex  items-center justify-between border-y border-white py-2 font-[Roboto] text-xl font-[400] uppercase leading-[20px]">
        <div className="w-full">{title}</div>
        <div>
          <CloseIcon
            className="pointer-events-auto w-4 cursor-pointer transition duration-200 ease-in-out hover:scale-125"
            onClick={() => setActionList(undefined!)}
          />
        </div>
      </div>

      <div className="overflow-auto">
        <div className="item-group mb-8 flex flex-row items-start">
          {links.map((link, i) => (
            <ResourceLink key={`action-list-link-${i}`} link={link} />
          ))}
        </div>

        <div className="flex flex-col items-start">
          {list.map((item) => (
            <Item key={item.label} action={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionList;
