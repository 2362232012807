import { useEffect } from "react";
import useLoaderStore from "./store";

const Loading = () => {
  const startLoading = useLoaderStore((state) => state.startLoading);
  const completeLoading = useLoaderStore((state) => state.completeLoading);

  useEffect(() => {
    startLoading();
    return () => completeLoading();
  }, []);
  return null;
};

export default Loading;
