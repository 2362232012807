import { Environment } from "@react-three/drei";
import CameraControls from "../../components/CameraControls";
import Model from "./components/Model";
import Hotspots from "../../components/Hotspots";
import { Suspense, useState, useEffect } from "react";
import Loading from "../../components/Loading";
import DevTools from "../../components/DevTools";
import CameraAnimation from "../../components/CameraAnimation";
import useModelStore from "./store";

interface SceneProps {
  model: Model;
}



const Scene = ({ model }: SceneProps) => {
  const model_diagonal = useModelStore((state) => state.diagonal);
  const [cameraDistance, setCameraDistance] = useState<number>();

  useEffect(() => {
    if (model_diagonal) {
      const distance = model_diagonal * 1;
      setCameraDistance(distance);
    }
  }, [model_diagonal, model, cameraDistance, setCameraDistance]);



  return (
    <>{cameraDistance&&
      <CameraControls
        position={[cameraDistance, cameraDistance/2, cameraDistance]}
        min_distance={cameraDistance*0.2}
        max_distance={cameraDistance*6}
      />}
      {model.edit && <DevTools.PinpointControl />}
      <Suspense fallback={<Loading />}>
        <Hotspots hotspots={model.hotspots} />
        {cameraDistance && <CameraAnimation 
        move_to={[0, cameraDistance/4, cameraDistance]}  
        look_at={[0,0,0]}/>}
        <Model model={model} />
      </Suspense>
    </>
  );
};

export default Scene;
