import { ReactNode, useState } from "react";
import { Dialog } from "@headlessui/react";
import { ReactComponent as CloseIcon } from "../icons/close.svg";

interface ModalProps {
  children: ReactNode;
  title?: string;
  expandHeight?: boolean;
  onClose?: () => void;
}

const Modal = ({
  children,
  title,
  expandHeight = true,
  onClose = () => null,
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="fixed inset-0 z-[2000] overflow-y-auto"
    >
      <div className="modal-dialog m-auto flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />

        <div
          className={`modal-body relative flex ${
            expandHeight ? "h-screen" : "max-h-screen md:h-screen"
          }  w-full max-w-[1100px] flex-col rounded bg-black p-8`}
        >
          <Dialog.Title as="div" className="flex items-start justify-between">
            <h2 className="modal-title flex h-full items-center font-['Rubik'] text-xl font-[600] uppercase text-white">
              {title}
            </h2>
            <div>
              <div
                className="modal-close group flex h-[40px] w-[40px] cursor-pointer items-center justify-center border-2 border-white hover:bg-white"
                onClick={() => handleClose()}
              >
                <CloseIcon className="w-5 cursor-pointer text-white group-hover:text-black" />
              </div>
            </div>
          </Dialog.Title>
          {children}
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
