import { useEffect, useState } from "react";
import useHotspotStore from "../../store";

const useAudioAction = (action: AudioAction) => {
  const [active, setActive] = useState(false);
  const setAudio = useHotspotStore((state) => state.setAudio);
  const audio = useHotspotStore((state) => state.audio);

  const trigger = () => {
    if (active) {
      setAudio();
    } else {
      setAudio(action.audio);
    }
    setActive(!active);
  };

  useEffect(() => {
    if (active && (!audio || audio.src != action.audio.src)) {
      setActive(false);
    }
  }, [audio]);

  return [active, trigger] as const;
};

export default useAudioAction;
