import Hotspot from "./Hotspot";
import Pinpoint from "./Pinpoint";

interface HotspotsProps {
  hotspots?: Hotspot[];
}

const Hotspots = ({ hotspots = [] }: HotspotsProps) => {
  return (
    <>
      {hotspots.map((hotspot) => {
        if (!hotspot.action)
          return (
            <Pinpoint
              key={`hotspot-${hotspot.pinpoint.position.join("-")}`}
              pinpoint={hotspot.pinpoint}
            />
          );

        return (
          <Hotspot
            key={`hotspot-${hotspot.pinpoint.position.join("-")}`}
            hotspot={hotspot}
          />
        );
      })}
    </>
  );
};

export default Hotspots;
