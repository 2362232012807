import useHotspotStore from "../../store";

const useCameraAction = (action: CameraAction) => {
  // TODO: Manage active camera state
  const active = false;
  const { setCamera } = useHotspotStore();

  const trigger = () => {
    setCamera(action.look_at, action.move_to);
  };

  return [active, trigger] as const;
};

export default useCameraAction;
