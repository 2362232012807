import ImageElem from "./ImageElem";

interface ImageModalProps {
  content: ImageModal;
}

const ImageModal = ({ content: { src, text } }: ImageModalProps) => {
  return (
    <div className="flex grow flex-col justify-center overflow-auto">
      <ImageElem className="min-h-0 basis-full object-contain py-3" src={src} />
      {text && (
        <div className="modal-text basis-32 overflow-y-auto text-white">
          {text}
        </div>
      )}
    </div>
  );
};

export default ImageModal;
