import { animated, useSpring } from "@react-spring/three";
import { ReactNode } from "react";
import { Vector3Tuple } from "three";
import useHotspotStore from "../../../../components/Hotspots/store";
import useModelStore from "../../store";

type GroupElementProps = JSX.IntrinsicElements["group"];

interface GLTFModelAnimatedGroupProps extends GroupElementProps {
  children: ReactNode;
}

const AnimatedGroup = ({
  children,
  position: _,
  ...props
}: GLTFModelAnimatedGroupProps) => {
  const groups = useModelStore((state) => state.animated_groups);
  const { active, animation } = groups.find(
    (group) => group.group_name === props.name,
  )!;
  const setActiveAnimation = useHotspotStore(
    (state) => state.setActiveAnimation,
  );

  const { position } = useSpring<{ position: Vector3Tuple }>({
    to: {
      position: active ? animation : [0, 0, 0],
    },
    onStart: () => setActiveAnimation(true),
    onRest: () => setActiveAnimation(false),
  });

  return (
    <animated.group position={position} {...props}>
      {children}
    </animated.group>
  );
};

export default AnimatedGroup;
