import useHotspotStore from "../Hotspots/store";
import Toggle from "../Toggle";

const ToggleLabels = () => {
  const setShowLabels = useHotspotStore((state) => state.setShowLabels);
  const show_labels = useHotspotStore((state) => state.show_labels);

  return (
    <div className="label-area pointer-events-auto flex justify-end">
      <Toggle checked={show_labels} onChange={(value) => setShowLabels(value)}>
        <div
          style={{ textShadow: "2px 2px 0px #000000" }}
          className="text-base text-white"
        >
          Etichette
        </div>
      </Toggle>
    </div>
  );
};

export default ToggleLabels;
