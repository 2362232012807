interface TextModalProps {
  content: TextModal;
}

const TextModal = ({ content: { text } }: TextModalProps) => {
  return (
    <div className="flex grow flex-col justify-center overflow-auto">
      <div
        className="modal-text mt-5 h-full overflow-y-auto text-white md:mb-5"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
};

export default TextModal;
