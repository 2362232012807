import { CSSProperties, ReactNode } from "react";

export interface AreaProps {
  area: string;
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Area = ({ children, area, className = "", style = {} }: AreaProps) => {
  return (
    <div className={className} style={{ gridArea: area, ...style }}>
      {children}
    </div>
  );
};

export default Area;
