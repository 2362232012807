import { useState } from "react";
import useHotspotStore from "../../store";

const useListAction = (action: ListAction) => {
  const [active, setActive] = useState(false);
  const setActionList = useHotspotStore((state) => state.setActionList);

  const trigger = () => {
    setActionList(action);
  };

  return [active, trigger] as const;
};

export default useListAction;
