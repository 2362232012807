import { Html } from "@react-three/drei";
import HotspotIcon from "../../icons/hotspot";
import useHotspotStore from "./store";
import Label from "./Label";

interface PinpointProps {
  pinpoint: Pinpoint;
  onClick?: () => void;
}

const Pinpoint = ({
  pinpoint: { position = [0, 0, 0], icon, label, dimension },
  onClick = () => null,
}: PinpointProps) => {
  const occlusion_collider_list = useHotspotStore(
    (state) => state.occlusion_collider_list,
  );
  const show_labels = useHotspotStore((state) => state.show_labels);
  const show_labels_distance = useHotspotStore(
    (state) => state.show_labels_distance,
  );

  return (
    // zIndexRange limited to 1000 to overlap on OverlayGrid
    <Html
      center
      position={position}
      zIndexRange={[1000, 0]}
      occlude={occlusion_collider_list}
      wrapperClass="hover:!z-[1001]"
    >
      <div className="relative">
        <div
          className={`flex ${
            dimension ? "h-" + dimension + " w-" + dimension : "h-11 w-11"
          }  group flex-row items-end drop-shadow-2xl`}
        >
          {icon && (
            <HotspotIcon
              icon={icon}
              className="w-fit cursor-pointer text-white transition duration-200 ease-in-out group-hover:scale-125"
              onClick={() => onClick()}
            />
          )}
          {label && (
            <div
              className={` ${icon ? "left-full" : ""} ${
                (show_labels && show_labels_distance) ||
                "hidden group-hover:ml-1.5"
              }  pointer-events-none absolute group-hover:inline`}
            >
              <Label text={label} />
            </div>
          )}
        </div>
      </div>
    </Html>
  );
};

export default Pinpoint;
