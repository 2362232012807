import OpenSeaDragon from "openseadragon";
import { useEffect } from "react";
import { ReactComponent as Plus } from "../icons/plus.svg";
import { ReactComponent as Minus } from "../icons/minus.svg";

interface GigapixelViewerProps {
  tileSources: string;
}

const GigapixelViewer = ({ tileSources }: GigapixelViewerProps) => {
  useEffect(() => {
    document
      .querySelector("#gigapixel-wrapper .openseadragon-container")
      ?.remove();

    OpenSeaDragon({
      id: "gigapixel-wrapper",
      visibilityRatio: 1.0,
      showNavigator: true,
      constrainDuringPan: true,
      zoomInButton: "zoom-in",
      zoomOutButton: "zoom-out",
      homeButton: undefined,
      fullPageButton: undefined,
      tileSources: [tileSources],
    });
  }, [tileSources]);

  if (!tileSources) return null;

  return (
    <div className="relative h-full w-full" id="gigapixel-wrapper">
      <div className="absolute bottom-2 right-2 z-10 flex flex-row items-center gap-2">
        <button
          id="zoom-in"
          className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-[3px] border-white p-[8px] text-white hover:bg-white hover:text-black focus-visible:outline-none"
        >
          <Plus className="w-[17px]" />
        </button>
        <button
          id="zoom-out"
          className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-[3px] border-white p-[8px] text-white hover:bg-white hover:text-black focus-visible:outline-none"
        >
          <Minus className="w-[17px]" />
        </button>
      </div>
    </div>
  );
};

export default GigapixelViewer;
